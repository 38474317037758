<script setup lang="ts">
import { ReviewProps } from "./Review.props"

defineProps<ReviewProps>()

const { closeModal } = useModal()
</script>

<template>
  <div class="card-review-wrapper mx-4 flex flex-col">
    <div
      class="
        review-container
        relative
        flex flex-col
        gap-6
        rounded-t-lg
        bg-white
        p-4
        pb-6
      "
    >
      <div class="flex flex-wrap items-center gap-8">
        <div
          class="
            star-container
            flex
            gap-1
            rounded-full
            bg-grey-main
            px-5
            py-2
            text-yellow-main
          "
        >
          <UtilsStars :size="5" :stars="rating" />
        </div>

        <p class="beaver-bold text-green-main">{{ rating }} su 5</p>

        <UtilsIcon
          name="Close.svg"
          class="absolute right-2 top-2 h-6 w-6 cursor-pointer"
          @click="closeModal('review')"
          tabindex="0"
        />
      </div>

      <UtilsMarkdown
        v-if="textReview"
        :content="textReview"
        class="beaver text-black-main"
      />
    </div>

    <div
      v-if="avatarImage"
      class="
        reviewer-container
        flex
        items-center
        gap-2
        rounded-b-lg
        bg-grey-main
        p-4
        md:gap-4
      "
    >
      <UtilsIcon
        name="Feedaty3.svg"
        v-if="avatarImage === 'feedaty'"
        class="h-10 w-10"
      />
      <UtilsIcon
        name="TrustpilotCircle.svg"
        v-else-if="avatarImage === 'trustpilot'"
        class="h-10 w-10"
      />
      <UtilsWrapperImage
        v-else
        class="h-10 w-10 overflow-hidden rounded-full"
        v-bind="avatarImage"
      />

      <span class="beaver-bold"> {{ first }} {{ truncateSurname }} </span>
    </div>
  </div>
</template>
