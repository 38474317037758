<script setup lang="ts">
import { ReviewProps } from "./Review.props"

const props = withDefaults(defineProps<ReviewProps>(), {
  rating: 0,
  maxChart: 150,
  textReview: ""
})

const [first, last] = props.reviewerName.split(" ")
const truncateSurname = computed(() => (last ? `${last.charAt(0)}.` : ""))

const maxLength = ref(props.maxChart)

const truncateString = (str: string, num: number) => {
  if (str.length <= num) {
    return "“" + str + "”"
  }
  return "“" + str.slice(0, num) + "...”"
}

const isVisibleShowMore = (review: string) => {
  return review && review.length > props.maxChart
}
</script>

<template>
  <div
    class="
      card-review-wrapper
      flex
      h-full
      w-[288px]
      transform-gpu
      flex-col
      drop-shadow-01
      md:w-auto
    "
  >
    <div class="review-container h-full rounded-t-lg bg-grey-main p-4">
      <div class="flex flex-wrap items-center gap-2">
        <div
          class="
            star-container
            flex
            gap-1
            rounded-full
            bg-white
            px-5
            py-2
            text-yellow-main
          "
        >
          <UtilsStars :size="5" :stars="rating" />
        </div>

        <p class="beaver-bold text-green-main">{{ rating }} su 5</p>
      </div>

      <div
        class="mt-2"
        :class="isVisibleShowMore(textReview) ? 'mb-3' : 'mb-8'"
      >
        <UtilsMarkdown
          v-if="textReview"
          :content="truncateString(textReview, maxLength)"
          class="beaver mb-1 text-black-main"
        />

        <UtilsButton
          v-if="isVisibleShowMore(textReview)"
          @click="$emit('onReadMore')"
          class="custom-btn-underlined custom-btn-no-padding"
          theme="ghost-green"
          :text="$t('card.review.readMore')"
          order="last"
        />
      </div>

      <span v-if="date" class="mouse text-black-40">
        {{ date }}
      </span>
    </div>

    <div
      v-if="avatarImage"
      class="
        reviewer-container
        flex
        items-center
        gap-4
        rounded-b-lg
        bg-white
        p-4
      "
    >
      <UtilsIcon
        name="Feedaty3.svg"
        v-if="avatarImage === 'feedaty'"
        class="h-10 w-10"
      />
      <UtilsIcon
        name="TrustpilotCircle.svg"
        v-else-if="avatarImage === 'trustpilot'"
        class="h-10 w-10"
      />
      <UtilsWrapperImage
        v-else
        class="h-10 w-10 overflow-hidden rounded-full"
        v-bind="avatarImage"
      />

      <span class="beaver-bold"> {{ first }} {{ truncateSurname }} </span>
    </div>
  </div>
</template>
