<script setup lang="ts">
import type { ComponentReviewsProps } from "./ComponentReviews.props"
const props = defineProps<ComponentReviewsProps>()
</script>

<template>
  <div class="cms-component-reviews" id="cms-component-reviews">
    <RailsReviews v-if="props" v-bind="props" />
  </div>
</template>
