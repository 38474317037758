<script setup lang="ts">
import { ReviewsProps } from "./Reviews.props"
import { smOptions, mdOptions } from "./Reviews.carouselOptions"
import { sm } from "~/utils/breakpoints"

defineProps<ReviewsProps>()

const carouselOptions = computed(() => (sm.value ? smOptions : mdOptions))

const activeIndex = ref(0)

const { openModal } = useModal()

const handleReadMore = (index: number) => {
  openModal("review")
  activeIndex.value = index
}
</script>

<template>
  <section
    class="
      rails-reviews
      padded
      carousel-container-overflowed
      bg-gradient-to-r
      from-yellow-10
      to-white
      py-4
      md:py-12
    "
  >
    <p
      v-if="caption"
      class="snail md:pig-medium mb-2 uppercase md:mb-3 md:text-center"
    >
      {{ caption }}
    </p>
    <p
      v-if="title"
      class="
        elephant-bold
        md:mammoth-bold
        mb-3
        text-green-main
        md:mb-4
        md:text-center
        lg:mb-4
      "
    >
      {{ title }}
    </p>

    <div
      class="mb-6 flex flex-wrap items-center"
      v-if="totalReviews || averageStarReviews"
    >
      <div class="mb-2 flex w-full flex-wrap items-center md:justify-center">
        <div
          class="
            star-container
            mr-2
            flex
            gap-1
            rounded-full
            bg-white
            px-5
            py-2
            text-yellow-main
          "
        >
          <UtilsStars :size="5" :stars="averageStarReviews" />
        </div>

        <p class="beaver-bold text-green-main">{{ averageStarReviews }} su 5</p>
      </div>
      <p
        class="beaver w-full text-black-main md:text-center"
        v-if="totalReviews"
      >
        {{ $t("rails.reviews.total", { total: totalReviews }) }}
      </p>
    </div>

    <DialogsAndModalsUtilsModal id="review">
      <DialogsAndModalsReview v-bind="reviewCards[activeIndex]" />
    </DialogsAndModalsUtilsModal>

    <AppCarousel
      v-if="reviewCards?.length"
      class="carousel-overflowed select-none"
      :swiper-props="carouselOptions"
      :items="reviewCards"
      :theme="carouselOptions?.theme"
      :key="sm ? 1 : 0"
    >
      <template #default="{ item, slideIndex }">
        <CardReview
          class="swiper-tile"
          v-bind="item"
          @on-read-more="handleReadMore(slideIndex)"
        />
      </template>
    </AppCarousel>
  </section>
</template>

<style lang="scss" scoped>

:deep(.swiper-slide:first-child > div) {
  margin-left: 0;
}

:deep(.swiper-slide:last-child > div) {
  margin-right: 0;
}
.swiper-tile {
  margin: 0 8px;
}

@screen md {
  .swiper-tile {
    margin: 0 12px;
    width: calc(((100vw - 142px) / 4) - 24px);
  }
}

@screen lg {
  .swiper-tile {
    width: calc(((100vw - 456px) / 4) - 24px);
  }
}

</style>
